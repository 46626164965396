import React from 'react';
import { graphql } from 'gatsby';
import { QueryFragments } from '../GraphQl/queryFragments'; // eslint-disable-line

//  Components
import { PageMeta } from '../components/pageMeta';
import BlockZone from '../components/blockZone/zone.development';
import StickyServicesNav from '../components/stickyServicesNav/stickyServicesNav';

import { useScrollContext } from '../components/layout';

const PageTemplate = ({ data: { page } }) => {
  const { meta, pageBlocks } = page || {};
  const { scrollDefined } = useScrollContext();

  return (
    <>
      <PageMeta {...meta} />
      {scrollDefined && <>{pageBlocks && <BlockZone {...pageBlocks} />}</>}
      <StickyServicesNav />
    </>
  );
};

export default PageTemplate;

export const pageQuery = graphql`
  query standardPageQuery($slug: String!) {
    page: sanityPage(slug: { current: { eq: $slug } }) {
      meta {
        ...MetaCardFields
      }
      pageBlocks {
        ...BlockZone
      }
    }
  }
`;
